import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {WorkStream} from '../../work-stream';
import {ActivatedRoute} from '@angular/router';
import {TechnologyService} from '../../technology.service';
import Stream from '../../category.json';
import { Category } from '../../category';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';


@Component({
  selector: 'app-tech-radar-info-dialog',
  templateUrl: './tech-radar-info-dialog.component.html',
  styleUrls: ['./tech-radar-info-dialog.component.scss']
})
export class TechRadarInfoDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public workStream: WorkStream) { }

  streams: Category[] = [];
  public Stream = Stream;

  ngOnInit(): void {
    /* Read from json*/
    const foundStream = Stream.streams.find(param => param.name === this.workStream)
    if (foundStream) {
      this.streams = foundStream["categories"]
    }
  }

}
