import {Component, OnInit} from '@angular/core';
// import {EnvService} from './env.service';
import {MatDialog} from '@angular/material/dialog';
import {CookieConsentComponent} from './privacy/cookie-consent/cookie-consent.component';
import {Router} from '@angular/router';
import {ThirdPartyManagerService} from './third-party-manager.service';
import {ThirdParty, ThirdPartyModel} from './privacy/privacy-policy/third-party.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Alexander Thamm GmbH - Tech Radar';
  showSearchBar = false;

  constructor(private dialog: MatDialog, private router: Router,
              private thirdPartyManagerService: ThirdPartyManagerService){}

  ngOnInit(): void {
   // alert('<cookies-consent-message>')
    this.updateThirdParty();
  }


  updateThirdParty(): void {
    if (!localStorage.getItem(ThirdParty.COOKIE_STORAGE_NAME)) {
      this.dialog.open(CookieConsentComponent, {width: '500px', height: '420px', disableClose: true, hasBackdrop: true});
    } else {
     // const policies: ThirdPartyModel[] = JSON.parse(localStorage.getItem(ThirdParty.COOKIE_STORAGE_NAME));
      let cookiesItem = localStorage.getItem("attechradar_cookies")
      if(cookiesItem) {
        const policies: ThirdPartyModel[] = JSON.parse(cookiesItem);
        this.thirdPartyManagerService.update(policies);
      }
    }
  }


  hasRoute(route: string): boolean {
    return this.router.url.includes(route);
  }
}
