import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TechnologyDetailsComponent} from './technology-details/technology-details.component';
import {TechnologyOverviewComponent} from './technology-overview/technology-overview.component';
import {WorkStreamOverviewComponent} from './work-stream-overview/work-stream-overview.component';
import {PrivacyPolicyComponent} from './privacy/privacy-policy/privacy-policy.component';

const routes: Routes = [
  { path: '', redirectTo: '/technologies', pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'technologies', component: WorkStreamOverviewComponent },
  { path: 'technologies/:workStream', component: TechnologyOverviewComponent },
  { path: 'technologies/:workStream/:id', component: TechnologyOverviewComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
