import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private searchSource = new BehaviorSubject('');
  currentSearchString = this.searchSource.asObservable();

  constructor() { }

  changeSearchString(searchString: string): void {
    if (searchString !== undefined) {
      this.searchSource.next(searchString);
    }
  }
}
