import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
/*
import { EnvService } from './app/env.service';


import {ReflectiveInjector} from '@angular/core';
var injector = ReflectiveInjector.resolveAndCreate([EnvService]);
var env = injector.get(EnvService)

if (env.production) {
  enableProdMode();
  console.log('Production mode enabled!');
}
*/

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
