<h2 mat-dialog-title>Technology Lifecycles</h2>

<mat-dialog-content class="mat-typography">
  <p><b>ADOPT</b> ring represent technologies where there's no doubt that it's proven and mature for use.</p>
  <p><b>TRIAL</b> ring represent technologies that we think are ready for use, but they are not as
    completely proven as those in the Adopt ring. We should use these on a trial basis in a pilot or a low-risk
    projects,
    to decide whether they should be part of our toolkit.</p>
  <p><b>ASSESS</b> ring represent technologies that we think are interesting and worth keeping an eye
    on, but not necessarily trial yet. We should invest some effort (such as development spikes, research projects,
    attend conference sessions, etc.) to see if it might become important for us in the future.</p>
  <p><b>HOLD</b> ring means "don't start anything new with this technology". Hold ring represent
    technologies we haven't had a good experience with and you should think twice about using this technology
    for new development. It might still be used in the project if there are no other alternatives.</p>

  <br/>

  <p>◉ moved up or down, ● no change</p>

  <br/>

</mat-dialog-content>

<h2 mat-dialog-title>Technology Categories</h2>
<mat-dialog-content class="mat-typography" >
    <div *ngFor="let stream of streams">
      <p><b>{{stream.category}}</b>: {{stream.description}} </p>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>