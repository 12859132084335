import {Component, Inject, OnInit} from '@angular/core';
import {Technology} from '../technology';
import {ActivatedRoute} from '@angular/router';
import {TechnologyService} from '../technology.service';
import {DatePipe} from '@angular/common';
import {NewTechnologyComment} from '../new-technology-comment';
import {EvaluationLog} from '../evaluation-log';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

@Component({
  selector: 'app-technology-details',
  templateUrl: './technology-details.component.html',
  styleUrls: ['./technology-details.component.scss']

})


export class TechnologyDetailsComponent implements OnInit {

  technology!: Technology;

  state = [
    {color: '#8B8889', name: 'Hold'},
    {color: '#8BA1BB', name: 'Assess'},
    {color: '#3B5981', name: 'Trial'},
    {color: '#009640', name: 'Adopt'}
  ];

  sortedStates!: string[];

  collapsed = true;
  latestExperience!: EvaluationLog;

  evaluationLog: EvaluationLog[] = [];

  // Comment Input Fields
  comment!: string;
  commentBy!: string;
  commentProject!: string;
  commentVersion!: string;

  selectedState!: string;

  constructor(private route: ActivatedRoute,
              private datePipe: DatePipe,
              private technologyService: TechnologyService,
              @Inject(MAT_DIALOG_DATA) public data: Technology) {
  }

  ngOnInit(): void {
    this.technology = this.data;
    this.evaluationLog = this.data['evaluation-logs']
      .filter(evaluationLog => evaluationLog.date != null)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    this.latestExperience = this.evaluationLog[0];
    this.createSortedStates();
  }

  // temp disabled till we activate comments feature again
  addCommentToTechnology(): void {
    let dateValue = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    if (dateValue === null) {
      dateValue = '';
    }
    const newTechnologyComment: NewTechnologyComment = {
      comment: this.comment,
      date: dateValue,
      commentBy: this.commentBy,
      email: this.commentBy,
      technologyId: this.technology._id,
      project: this.commentProject,
      version: this.commentVersion
    };

    this.technologyService.addCommentToTechnology(newTechnologyComment).subscribe( //data => {
      // this.getTechnology();
    //}
    );
  }

  createSortedStates(): void {
    const stateSorted: string [] = this.state.filter(value =>
      value.name.toLowerCase() !== this.latestExperience?.evaluation?.toLowerCase()
      && value.name.toLowerCase() !== this.latestExperience?.tendency?.toLowerCase()).map(value => value.name);

    stateSorted.push(this.latestExperience?.evaluation);
    if (this.latestExperience?.tendency && stateSorted.indexOf(this.latestExperience?.tendency) === -1) {
      stateSorted.push(this.latestExperience?.tendency);
    }

    this.sortedStates = stateSorted;
  }

  getStateDescription(state: string): string {
    if (state?.toLocaleLowerCase() === 'adopt') {
      return 'ADOPT represent technologies where there\'s no doubt that it\'s proven and mature for use.';
    } else if (state?.toLocaleLowerCase() === 'trial') {
      return 'TRIAL represent technologies that we\n' +
        '          think are ready for use, but they are not as completely proven as those in the Adopt ring. We should use these\n' +
        '          on a trial basis in a pilot or a low-risk projects, to decide whether they should be part of our toolkit.';
    } else if (state?.toLocaleLowerCase() === 'assess') {
      return 'ASSESS represent technologies that we\n' +
        '          think are interesting and worth keeping an eye on, but not necessarily trial yet. We should invest some effort\n' +
        '          (such as development spikes, research projects, attend conference sessions, etc.) to see if it might become\n' +
        '          important for us in the future.';
    } else if (state?.toLocaleLowerCase() === 'hold') {
      return 'HOLD means don\'t start anything new\n' +
        '          with this technology". Hold ring represent technologies we haven\'t had a good experience with and you should\n' +
        '          think twice about using this technology for new development. It might still be used in the project if there\n' +
        '          are no other alternatives.';
    } else {
      return 'UNKNOWN'}
  }
}
