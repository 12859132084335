<mat-sidenav-container class="tech-radar-split">

  <mat-sidenav fxHide fxShow.gt-sm position="end" class="tech-radar-split-menu" mode="side" opened>

    <app-tech-radar-legend #sidebar [segmentedTechnologies]="segmented"
                           [quadrantNames]="getQuadrantNames()"
                           [rings]="getValidRings()"
                           [hoveredTechnology]="hoveredTechnology"
                           (technologyClick)="handleTechnologyClick($event)"
                           (mouseOverLegendEntry)="onMouseOverLegendEntry($event)"
                           (mouseLeaveLegendEntry)="onMouseLeaveLegendEntry()">
    </app-tech-radar-legend>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="info-icon">
      <mat-icon class="big-icon" matTooltip="Show technology lifecycle" aria-hidden="false"
                aria-label="info" (click)="openInfoDialog()"> info
      </mat-icon>
    </div>
    <div [ngClass.gt-sm]="'radar-sm'"
         ngClass.gt-md="radar"
         [ngClass]="'radar-lt-sm'">
      <svg id="radar"></svg>
    </div>

    <div fxShow fxHide.gt-sm>
      <app-tech-radar-legend #sidebar [segmentedTechnologies]="segmented"
                             [quadrantNames]="getQuadrantNames()"
                             [rings]="getValidRings()"
                             [hoveredTechnology]="hoveredTechnology"
                             (technologyClick)="handleTechnologyClick($event)"
                             (mouseOverLegendEntry)="onMouseOverLegendEntry($event)"
                             (mouseLeaveLegendEntry)="onMouseLeaveLegendEntry()">
      </app-tech-radar-legend>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
