<div fxLayout="column" class="full-width" fxFlexFill>


  <mat-toolbar>
    <mat-toolbar-row>
      <div fxLayout="row" fxLayoutAlign="start center" class="full-width">
        <div fxFlex.gt-sm="65" fxFlex="40" fxLayoutGap="0.5rem" fxLayoutAlign="start center">
          <div class="toolbar-at-logo-container">
            <a routerLink="/technologies"> <img src="../assets/techradar_logo_white.png" id="toolbar-at-logo"></a>
          </div>
          <div fxHide fxShow.gt-sm>
            <a mat-button [routerLink]="'/technologies/machine-learning'" routerLinkActive="active-link">Machine
              Learning</a>
            <a mat-button [routerLink]="'/technologies/data-engineering'" routerLinkActive="active-link">Data
              Engineering</a>
            <a mat-button [routerLink]="'/technologies/data-visualization'" routerLinkActive="active-link">Data
              Visualization</a>
          </div>
        </div>

        <div fxFlex="35"
             fxHide fxShow.gt-sm fxLayout="row"
             fxLayoutAlign="end center"
             *ngIf="hasRoute('/technologies/data-engineering') || hasRoute('/technologies/machine-learning') || hasRoute('/technologies/data-visualization')">
          <div fxFlex="95" fxLayoutAlign="end center" *ngIf="showSearchBar">

            <app-techradar-searchbar
              (closeSearchbar)="showSearchBar = false"
              class="full-width"></app-techradar-searchbar>
          </div>
          <div *ngIf="!showSearchBar"
               fxFlex="5" fxHide fxShow.gt-sm fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="showSearchBar = true" disableRipple>
              <mat-icon class="menu-icon">search</mat-icon>
            </button>
          </div>

        </div>

        <div fxFlex="60" fxShow fxHide.gt-sm fxLayout="row">
          <div fxFlex="88"
               *ngIf="hasRoute('/technologies/data-engineering') || hasRoute('/technologies/machine-learning') || hasRoute('/technologies/data-visualization')"
               fxLayoutAlign="end center">

            <div *ngIf="showSearchBar" class="full-width">
              <app-techradar-searchbar (closeSearchbar)="showSearchBar=false"
                                       class="full-width"></app-techradar-searchbar>
            </div>
            <div>
              <button mat-icon-button *ngIf="!showSearchBar"
                      (click)="showSearchBar=true" disableRipple>
                <mat-icon class="menu-icon">search</mat-icon>
              </button>
            </div>

          </div>


          <div fxFlex="12" fxLayoutAlign="center center">
            <button mat-icon-button (click)="snav.opened = !snav.opened" disableRipple>
              <mat-icon class="menu-icon">menu</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-toolbar-row>

  </mat-toolbar>

  <mat-sidenav-container class="tech-radar-split">

    <mat-sidenav #snav fxShow fxHide.gt-sm position="end" mode="over" [autoFocus]="false">
      <div class="techradar-sidemenu" fxLayout="column" fxLayoutGap="1rem">
        <br/>
        <a mat-button (click)="snav.opened=false" [routerLink]="'/technologies/machine-learning'"
           routerLinkActive="active-link">Machine Learning</a>
        <a mat-button (click)="snav.opened=false" [routerLink]="'/technologies/data-engineering'"
           routerLinkActive="active-link">Data Engineering</a>
        <a mat-button (click)="snav.opened=false" [routerLink]="'/technologies/data-visualization'"
           routerLinkActive="active-link">Data
          Visualization</a>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <div fxLayout="column" fxLayoutAlign="space-between center" class="full-width sidenav-content">

        <div class="content full-width">
          <router-outlet></router-outlet>
        </div>

        <div class="full-width">
          <!--Footer-->
          <mat-toolbar class="footer">
            <div fxLayout="row" class="footer">
              <div fxLayout="row" fxFlex="30" class="copyright" fxLayoutAlign="start center">
                <p> © 2025 Alexander Thamm GmbH </p>
              </div>

              <div fxLayout="row" fxFlex="70" fxLayoutAlign="end center" class="impressum"
                   fxLayoutGap="0.5rem">
                <a target="_blank" href="https://www.alexanderthamm.com/en/imprint/"> Imprint </a>
                <a [routerLink]="'/privacy-policy'"> Privacy policy </a>
              </div>
            </div>
          </mat-toolbar>
        </div>

      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
