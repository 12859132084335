<div *ngFor="let quadrant of quadrantNames; let q = index">
  <h1 class="tech-radar-category">{{ quadrant }}</h1>
  <div *ngFor="let ring of rings; let r = index" class="tech-radar-ring-container" [style.borderLeft]="'5px solid ' + ring.color">
    <h2 *ngIf="segmentedTechnologies[q][r].length > 0" class="tech-radar-ring" [style.color]="ring.color">{{ ring.name }}
      <span>
          <mat-icon *ngIf="isCollapsed(q,r)" class="toggle-icon" matTooltip="collapse" aria-hidden="false"
                    aria-label="collapse" (click)="toggle(q,r)">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="!isCollapsed(q,r)" class="toggle-icon" matTooltip="expand" aria-hidden="false"
                    aria-label="expand" (click)="toggle(q,r)">keyboard_arrow_up</mat-icon>
       </span>
    </h2>
    <mat-list *ngIf="!isCollapsed(q,r)">
      <mat-list-item *ngFor="let technology of segmentedTechnologies[q][r]"
                     (click)="onTechnologyClick(technology)"
                     (mouseover)="onMouseOverLegendEntry(technology)"
                     (mouseleave)="onMouseLeaveLegendEntry()"
                     class="tech-radar-technology-list-item {{ technology.id === hoveredTechnology.id ? 'hovered-technology': ''}}"> {{ technology.id }}. {{ technology.label }} </mat-list-item>
    </mat-list>
  </div>
</div>
