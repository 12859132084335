<div fxLayout="row" class="full-width" fxLayoutAlign="end center">
  <button mat-icon-button matDialogClose>
    <mat-icon class="dialog-close-icon {{this.latestExperience.evaluation.toLowerCase()}}" fxLayoutAlign="space-evenly center">cancel</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center start">
  <div fxFlex="80" fxFlex.gt-lg="65" fxFlex.lt-sm="100" fxLayout="column" fxLayoutAlign="start center"
       fxLayoutGap.gt-sm="3rem"
       fxLayoutGap="1rem"
       class="full-width">
    <div fxLayout="column"
         fxLayoutGap.gt-sm="10px"
         fxLayoutGap="5px" fxLayoutAlign="start center">
      <a class="technology-name" [href]="technology.url" target="_blank">{{ technology.name }}</a>


      <div class="categories" fxLayout="row" fxLayoutAlign="center center">
        {{ technology.category }}
        <mat-icon *ngIf="technology.subcategory">chevron_right</mat-icon>
        {{ technology.subcategory }}
      </div>


      <div fxLayout="row" fxLayoutAlign="center center">

        <button mat-icon-button shareButton="linkedin" #linkedInBtn="shareButton"
                [style.color]="linkedInBtn?.color">
          <fa-icon *ngIf="linkedInBtn.icon" [icon]="['fab', 'linkedin']"></fa-icon>
        </button>

        <button mat-icon-button shareButton="twitter" #twitterBtn="shareButton"
                [style.color]="twitterBtn?.color">
          <fa-icon *ngIf="twitterBtn.icon" [icon]="['fab', 'twitter']"></fa-icon>
        </button>

        <button mat-icon-button shareButton="email" #emailBtn="shareButton"
                [style.color]="emailBtn?.color">
          <fa-icon *ngIf="emailBtn.icon" [icon]="['fas', 'envelope']"></fa-icon>
        </button>

        <button mat-icon-button shareButton="copy" #copyBtn="shareButton"
                [style.color]="copyBtn?.color">
          <fa-icon *ngIf="copyBtn.icon" [icon]="['fas', 'link']"></fa-icon>
        </button>
      </div>
      <p class="center-text technology-description" *ngIf="technology.description!==''"
         [innerHTML]="technology.description || '-'"></p>
    </div>

    <div class="info-container" fxLayout="column" fxLayoutGap="1.5rem" class="full-width">
      <div fxLayout="row"
           class="full-width"
           fxLayoutAlign="center center" fxLayoutGap="0.5rem">

        <ng-container *ngFor="let state of sortedStates; let index=index">
          <div [matTooltip]="getStateDescription(state)"
               matTooltipClass="state-tooltip"
               #tooltip="matTooltip"
               (click)="tooltip.toggle()"
               matTooltipShowDelay="500"
               [class.hold-background]="latestExperience.evaluation.toLowerCase() === 'hold' && state.toLowerCase() === 'hold'"
               [class.assess-background]="latestExperience.evaluation.toLowerCase() === 'assess' && state.toLowerCase() === 'assess'"
               [class.trial-background]="latestExperience.evaluation.toLowerCase() === 'trial' && state.toLowerCase() === 'trial'"
               [class.adopt-background]="latestExperience.evaluation.toLowerCase() === 'adopt' && state.toLowerCase() === 'adopt'"
               class="default-category" fxFlex="25" fxLayoutAlign="center center">
            {{state}}
          </div>

          <mat-icon
            *ngIf="latestExperience?.tendency && sortedStates[index +1]?.toLowerCase() === latestExperience?.tendency?.toLowerCase() &&latestExperience?.tendency !== latestExperience.evaluation">
            double_arrow
          </mat-icon>
        </ng-container>
      </div>


      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between start"
           fxLayoutGap="1.5rem" fxLayoutGap.gt-sm="0"
           class="full-width">

        <div fxFlex="48"
             class="info-box full-width" fxLayout="column" fxLayoutAlign="start center"
             [class.info-box-hold]="latestExperience.evaluation.toLowerCase() === 'hold'"
             [class.info-box-assess]="latestExperience.evaluation.toLowerCase() === 'assess'"
             [class.info-box-trial]="latestExperience.evaluation.toLowerCase() === 'trial'"
             [class.info-box-adopt]="latestExperience.evaluation.toLowerCase() === 'adopt'">
          <div>
            <mat-icon class="info-icon material-icons-outlined {{latestExperience.evaluation.toLowerCase()}}">explore
            </mat-icon>
          </div>
          <div class="info-box-title">Our experience</div>
          <p *ngIf="technology.limitations!==''" [innerHTML]="latestExperience.reasoning || '-' "></p>
        </div>

        <div fxFlex="48" class="info-box full-width"
             fxLayout="column" fxLayoutAlign="start center"
             [class.info-box-hold]="latestExperience.evaluation.toLowerCase() === 'hold'"
             [class.info-box-assess]="latestExperience.evaluation.toLowerCase() === 'assess'"
             [class.info-box-trial]="latestExperience.evaluation.toLowerCase() === 'trial'"
             [class.info-box-adopt]="latestExperience.evaluation.toLowerCase() === 'adopt'">
          <div>
            <mat-icon class="info-icon material-icons-outlined {{latestExperience.evaluation.toLowerCase()}}">
              assignment_late
            </mat-icon>
          </div>
          <div class="info-box-title">Limitations</div>
          <p *ngIf="technology.limitations!==''" [innerHTML]="technology.limitations || '-'"></p>
        </div>

      </div>
    </div>

    <div class="contact-container" fxLayout="column" fxLayoutAlign="center center"
         fxLayoutGap="5px"
         fxLayoutGap.gt-sm="1.2rem">
      <div class="contact-us-title">Questions?</div>
      <a class="contact-us-link" href="https://www.alexanderthamm.com/en/contact/" target="_blank">Reach out to us</a>
    </div>
  </div>
</div>

<div fxLayout="row" class="full-width" fxLayoutAlign="end center">
  <div class="last-update-text">Last update {{latestExperience.date | date: 'dd.MM.yyy'}}</div>
</div>
