export class Constants {
    public static extent = 400; // extent from left to right side of radar
    public static whiteSpace = 20; // width of inner white space
    public static shiftBlibs = Constants.whiteSpace / 10 + 1; // factor who much blibs are away from inner white space

    public static quadrants = [
        { radial_min: 0, radial_max: 0.5, factor_x: Constants.shiftBlibs, factor_y: Constants.shiftBlibs,
          x: 150, y: 150, quadrant_name: 'Workflow Tools', de_name: 'DevOps', dv_name: 'BI-Tools' },
        { radial_min: 0.5, radial_max: 1, factor_x: -Constants.shiftBlibs, factor_y: Constants.shiftBlibs,
          x: -150, y: 150, quadrant_name: 'Extensions', de_name: 'Databases', dv_name: 'Code-based Apps' },
        { radial_min: -1, radial_max: -0.5, factor_x: -Constants.shiftBlibs, factor_y: -Constants.shiftBlibs,
          x: -150, y: -Constants.extent / 2, quadrant_name: 'Orchestration Tools', de_name: 'Processing', dv_name: 'Code-based Charts' },
        { radial_min: -0.5, radial_max: 0, factor_x: Constants.shiftBlibs, factor_y: -Constants.shiftBlibs,
          x: 150, y: -Constants.extent / 2, quadrant_name: 'Engines', de_name: 'Storage', dv_name: 'UX/UI Tools' }
      ];

    public static rings = [
        { radius: 204, color: '#009640', name: 'ADOPT' },  // Math.floor(Math.sqrt((400 * 400) - 1 * [(400 * 400 - 50 * 50)/4])) // 204
        { radius: 285, color: '#3B5981', name: 'TRIAL' }, // Math.floor(Math.sqrt((400 * 400) - 2 * [(400 * 400 - 50 * 50)/4]))
        { radius: 347, color: '#8BA1BB', name: 'ASSESS' }, // Math.floor(Math.sqrt((400 * 400) - 3 * [(400 * 400 - 50 * 50)/4]))
        { radius: Constants.extent, color: '#8B8889', name: 'HOLD' },
        { radius: 50, color: '#93c47d', name: '' }
      ];

    public static titleOffset = { x: -675, y: -420 };

    public static footerOffset = { x: -675, y: 420 };

    public static legendOffset = [
        { x: 450, y: 90 },
        { x: -675, y: 90 },
        { x: -675, y: -310 },
        { x: 450, y: -310 }
      ];

    public static colors = {
        background: '#fff',
        grid: '#bbb',
        inactive: '#ddd'
      };

    public static seed = 42;

    public static width = 1050;
    public static height = 800;

    public static title = 'Tech Radar Alexander Thamm GmbH';
}

export class FullRadarConstants {
    public static rectangles = [
        { x: -Constants.extent, y: -Constants.whiteSpace, width: 2 * Constants.extent,
          height: Constants.whiteSpace * 2, color: '#ffffff', name: 'horizontal'},
        { x: -Constants.whiteSpace, y: -Constants.extent, width: Constants.whiteSpace * 2,
          height: 2 * Constants.extent, color: '#ffffff', name: 'vertical'}
      ];

    public static whiteRectangles = [
        { x: -Constants.extent - 1, y: -Constants.whiteSpace, width: 2, height: Constants.whiteSpace * 2, color: '#ffffff', name: 'left'},
        { x: Constants.extent - 1, y: -Constants.whiteSpace, width: 2, height: Constants.whiteSpace * 2, color: '#ffffff', name: 'right'},
        { x: -Constants.whiteSpace, y: -Constants.extent - 1, width: Constants.whiteSpace * 2, height: 2, color: '#ffffff', name: 'up'},
        { x: -Constants.whiteSpace, y: Constants.extent - 1, width: Constants.whiteSpace * 2, height: 2, color: '#ffffff', name: 'down'}
      ];

    public static whiteRectanglesInnerCircle = [
        { x: -Constants.extent - 1, y: -Constants.whiteSpace, width: Constants.extent * 2, height: Constants.whiteSpace * 2, color: '#ffffff', name: 'left'},
        { x: -Constants.whiteSpace, y: -Constants.extent - 1, width: Constants.whiteSpace * 2, height: Constants.extent * 2, color: '#ffffff', name: 'up'},
      ];
}
