import { Injectable } from '@angular/core';
import {ThirdParty, ThirdPartyModel} from './privacy/privacy-policy/third-party.model';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyManagerService {

  private cookieUpdate = new BehaviorSubject('');
  currentCookieState = this.cookieUpdate.asObservable();


  constructor(private httpService: HttpClient) { }

  update(cookieSettings: ThirdPartyModel[]): void {
    for (const policy of cookieSettings) {
        if (policy.accept) {
          this.add(policy.name);
        } else {
          this.remove(policy.name);
        }
    }
    localStorage.setItem(ThirdParty.COOKIE_STORAGE_NAME, JSON.stringify(cookieSettings));
    this.cookieUpdate.next('');
  }

  private add(name: string): void {
    const id = name + '-thirdparty';
    const path = 'assets/' + name + '.js';

    this.httpService.get('assets/' + name + '.js', {responseType: 'text'})
      .subscribe(data => {
          if (!document.getElementById(id)) {
            const node = document.createElement('script'); // creates the script tag
            node.id = id;
            node.type = 'text/javascript'; // set the script type
            node.async = true; // makes script run asynchronously
            if (data.startsWith('https://')) {
              node.src = data;
            } else {
              node.innerHTML = data;
            }
            // append to head of document
            console.log(id + ' added.');
            document.getElementsByTagName('head')[0].appendChild(node);
          }
        }, error => console.error('Script "' + path + '" not found.'));
  }

  private remove(name: string): void {
    const id = name + '-thirdparty';

    if (document.getElementById(id)) {
      const node = document.getElementById(id);
      if(node) {
        document.getElementsByTagName('head')[0].removeChild(node);
        console.log(id + ' removed.');
      }
    }
  }
}
