const pathToFolderDataEngineering = 'assets/data/data-engineering/'
const pathToFolderDataVisualization = 'assets/data/data-visualization/'
const pathToFolderMachineLearning = 'assets/data/machine-learning/'

const dataEngineeringTechnologiesFiles = [
  "DataIQ","apache-airflow","apache-flink","apache-hudi","apache-kafka","apache-nifi",
"apache-pulsar","apache-spark-structured-streaming","apache-spark","aws-athena","aws-batch",
"aws-dynamodb","aws-ec2","aws-ecr","aws-ecs-fargate","aws-efs","aws-emr","aws-glue",
"aws-kinesis-stream","aws-lambda","aws-rds","aws-redshift","aws-s3","aws-sns","aws-sqs",
"azkaban","azure-blob-storage","azure-cosmos-db","azure-datalake-storage-gen1-standalone-service",
"azure-datalake-storage-gen2-file-storage-that-lives-within-azure-storage-account",
"azure-event-hub","azure-queue-storage","azure-search","azure-service-bus-queues",
"azure-synapse-analytics","azure-table-store","databricks","docker-compose-stack",
"gcp-bigquery","gcp-cloud-run","gcp-cloudsql","gcp-cloudstorage","gcp-compute-engine",
"google-cloud-storage","hdfs","kubernetes","minio","rabbitmq","snowflake", "starburst-galaxy", "terraform", "neo4j"
];
const dataVisualizationTechnologiesFiles = [
  "adobe-color","bokeh","colorbrewer","d3js","dash","data-visualization-catalogue","domo",
  "dribbble","dv-test1","dv-test2","figma","flexdashboard","ggplot2","kibana","klipfolio",
  "leaflet","looker","matplotlib","miro","networkx","obiee","observable","pinterest","plotly",
  "power-bi","qlik-sense","qlikview","reveal","sap-bo-lumira","seaborn","shiny","shinydashboard",
  "sisense","sketch","storytellingwithdata","streamlit","tableau","vector-magic","viridis","vizwiz"
];
const machineLearningTechnologiesFiles = [
  "allennlp","anaconda","azure-ml","dask","dvc","fastai","fbprophet","h2o","jax","jupyter-lab",
  "jupyter-notebooks","kubeflow","lightfm","lightgbm","metaflow","mlflow",
  "numpy","open-ai-gym","pandas","pdpbox","pytorch","r-package-fable","sagemaker","scikit-learn",
  "shap","spacy","statsmodels","tensorflow-2-0-keras","tensorflow-serving","xgboost",
  "retrieval-augmented-generation", "langchain", "azure-openai-service", "dall-e", "hugging-face-transformers",
  "pharia-ai"
]

export default {pathToFolderDataEngineering
    ,pathToFolderDataVisualization
    ,pathToFolderMachineLearning
    ,dataEngineeringTechnologiesFiles
    ,dataVisualizationTechnologiesFiles
    ,machineLearningTechnologiesFiles}

