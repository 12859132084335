import {Component, OnInit} from '@angular/core';
import {TechnologyService} from '../technology.service';
import {Technology} from '../technology';
import {WorkStream} from '../work-stream';
import {ActivatedRoute} from '@angular/router';
import {WorkStreamUtils} from '../utils/work-stream-utils';

@Component({
  selector: 'app-technology-overview',
  templateUrl: './technology-overview.component.html',
  styleUrls: ['./technology-overview.component.scss']
})
export class TechnologyOverviewComponent implements OnInit {

  constructor(private technologyService: TechnologyService,
              private route: ActivatedRoute) { }

  displayedColumns: string[] = ['id', 'name', 'description', 'owners', 'workStream', 'category', 'subcategory', 'limitations'];

  technologies!: Technology[];

  workStream!: WorkStream;

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      const workStreamParam = params.get('workStream');
      if (workStreamParam) {
        this.workStream = WorkStreamUtils.getWorkStreamEnumForString(workStreamParam);
        console.log(this.workStream);
        this.getTechnologies();
      }
    });
  }

  getTechnologies(): void {
    if (this.workStream === WorkStream.ML) {
      this.technologyService.getMachineLearningTechnologies().subscribe(
        technologies => this.technologies = technologies
      );
    } else if (this.workStream === WorkStream.DE) {
      this.technologyService.getDataEngineeringTechnologies().subscribe(
        technologies => this.technologies = technologies
      );
    } else {
      this.technologyService.getDataVisualizationTechnologies().subscribe(
        technologies => this.technologies = technologies
      );
    }
  }
}
