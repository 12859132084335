import { Component } from '@angular/core';
import {ThirdParty, ThirdPartyModel} from '../privacy-policy/third-party.model';
import {ThirdPartyManagerService} from '../../third-party-manager.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {

  essential =  true;
  statistics: boolean = false;

  constructor(private thirdPartyManagerService: ThirdPartyManagerService) { }

  onSaveAll(): void {
    const policies: ThirdPartyModel[] = [];
    policies.push(new ThirdPartyModel({name: ThirdParty.GOOGLE_TAGMANAGER, accept: true}));
    policies.push(new ThirdPartyModel({name: ThirdParty.GOOGLE_ANALYTICS, accept: true}));
    policies.push(new ThirdPartyModel({name: ThirdParty.HOTJAR, accept: true}));
    this.thirdPartyManagerService.update(policies);
  }

  onSaveSelected(): void {
    const policies: ThirdPartyModel[] = [];
    policies.push(new ThirdPartyModel({name: ThirdParty.GOOGLE_TAGMANAGER, accept: this.statistics}));
    policies.push(new ThirdPartyModel({name: ThirdParty.GOOGLE_ANALYTICS, accept: this.statistics}));
    policies.push(new ThirdPartyModel({name: ThirdParty.HOTJAR, accept: this.statistics}));
    this.thirdPartyManagerService.update(policies);
  }

}
