import {WorkStream} from '../work-stream';

export class WorkStreamUtils {

  static getWorkStreamEnumForString(workStream: string): WorkStream {
    switch (workStream) {
      case 'machine-learning':
        return WorkStream.ML;
      case 'data-engineering':
        return WorkStream.DE;
      case 'data-visualization':
        return WorkStream.DV;
      default:
        return WorkStream.ML;
    }
  }

}
