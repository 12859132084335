
<div class="cookie-headline">
  <img class="cookie-logo" src="../assets/techradar_logo_black.png"/> <h2 class="cookie-title" mat-dialog-title>Privacy Settings</h2>
</div>

<div id="cookie-consent">
  <mat-dialog-content class="mat-typography">
    <p>We use cookies on our website. Some of them are essential to maintain site performance and to show you relevant content.</p>
  </mat-dialog-content>

  <ul>
    <li><mat-checkbox class="at-checkbox" [ngModel]="essential" [disabled]="true">Essential</mat-checkbox></li>
    <li><mat-checkbox class="at-checkbox" [(ngModel)]="statistics">Statistics</mat-checkbox></li>
  </ul>

  <mat-dialog-actions align="center">
    <button mat-flat-button class="at-button" mat-dialog-close (click)="onSaveAll()">I agree with all</button>
  </mat-dialog-actions>

  <mat-dialog-actions align="center">
    <button mat-flat-button class="at-button" mat-dialog-close (click)="onSaveSelected()">I agree with selected option</button>
  </mat-dialog-actions>

  <mat-dialog-actions align="center">
    <button mat-flat-button class="at-button at-text-refuse" mat-dialog-close>I reject optional cookies</button>
  </mat-dialog-actions>
</div>

<div class="cookie-footer">
    <a class="cookie-footer-link" href="https://www.alexanderthamm.com/en/imprint/" tabindex="0" role="button">Imprint</a>
    <span class="cookie-footer-sep">|</span>
    <a class="cookie-footer-link" href="https://www.alexanderthamm.com/en/privacy-policy/" tabindex="0" role="button">Privacy Policy</a>
</div>
