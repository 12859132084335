import { Component, OnInit } from '@angular/core';
import {ThirdParty, ThirdPartyModel} from './third-party.model';
import {ThirdPartyManagerService} from '../../third-party-manager.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  cookies: any = new Map();

  constructor(private thirdPartyManagerService: ThirdPartyManagerService) {
  }

  ngOnInit(): void {
    this.thirdPartyManagerService.currentCookieState.subscribe(data => this.updateCookieSettings());
  }

  updateCookieSettings(): void {
    const cookieSettings = localStorage.getItem(ThirdParty.COOKIE_STORAGE_NAME);
    if (cookieSettings) {
      const policies: ThirdPartyModel[] = JSON.parse(cookieSettings);
      for (const policy of policies) {
        this.cookies.set(policy.name, policy.accept);
      }
    }
  }

  onChangeSlider(): void{
    const policies: ThirdPartyModel[] = [];
    policies.push(new ThirdPartyModel({name: ThirdParty.GOOGLE_TAGMANAGER, accept: this.cookies.get(ThirdParty.GOOGLE_ANALYTICS)}));
    policies.push(new ThirdPartyModel({name: ThirdParty.GOOGLE_ANALYTICS, accept: this.cookies.get(ThirdParty.GOOGLE_ANALYTICS)}));
    policies.push(new ThirdPartyModel({name: ThirdParty.HOTJAR, accept: this.cookies.get(ThirdParty.HOTJAR)}));
    this.thirdPartyManagerService.update(policies);
  }
}
