<div class="work-stream-overview-content full-width" fxLayoutAlign.gt-sm="center center">

  <div fxLayout.gt-sm="row" class="web-stream-grid-list-wide full-width"
       fxLayoutAlign.gt-sm="center center"
       fxLayout="column" fxLayoutGap.gt-sm="0" fxLayoutGap="20px">

    <div fxFlex.gt-sm="33.33" class="web-stream-item-wide web-stream-item-wide-left">
      <div class="work-stream-item-content">
        <mat-icon>code</mat-icon>
        <a class="work-stream-item-link" routerLink="/technologies/machine-learning">Machine Learning</a>
      </div>
    </div>

    <div fxFlex.gt-sm="33.33" class="web-stream-item-wide web-stream-item-wide-center">
      <div class="work-stream-item-content">
        <mat-icon>table_chart</mat-icon>
        <a class="work-stream-item-link" routerLink="/technologies/data-engineering">Data Engineering</a>
      </div>
    </div>

    <div fxFlex.gt-sm="33.33" class="web-stream-item-wide web-stream-item-wide-right">
      <div class="work-stream-item-content">
        <mat-icon>bar_chart</mat-icon>
        <a class="work-stream-item-link" routerLink="/technologies/data-visualization">Data Visualization</a>
      </div>
    </div>

  </div>

</div>
