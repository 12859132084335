<mat-sidenav-container  class="privacy-policy">
  <h1>Privacy Policy</h1>

  <p>Thank you for your interest in our website.<br>Due to your visit of our Website, we collect, process, and use your personal data. Therefore, we would like to inform you, that the protection of this information is our highest goal. Furthermore, your Data is protected by the context of legal regulations. The following information will help you understand how we handle your personal data, how and for what purposes the data is used, to whom we pass the data on and how we protect your personal information.</p>
  <p><strong>Responsible for processing your personal data in accordance with the European Data Protection Basic Regulation:</strong></p>
  <p>Alexander Thamm GmbH<br>Sapporobogen 6-8<br>80637 Munich<br>Phone: +49 (0) 89 307 608 80<br>E-mail: <a href="mailto:contact@alexanderthamm.com">contact@alexanderthamm.com</a></p>

  <h2>1 Data protection officer</h2>
  <p>If you have any questions regarding data protection, please contact our data protection officer S-CON DATENSCHUTZ, Kriegerstraße 44, 30161 Hannover, Germany (<a href="mailto:datenschutzteam267@s-con.de">datenschutzteam267@s-con.de</a>).</p>

  <h2>2 Collection and processing of data</h2>
  <p>Every access to our website and every retrieval of a file stored on the website is logged. The storage serves internal system-related and statistical purposes.</p>
  <span>Logged are:
    <ul>
      <li>Name of the retrieved file</li>
      <li>Date and time of retrieval</li>
      <li>transferred data volume</li>
      <li>Message about successful retrieval</li>
      <li>operating system used</li>
      <li>Browser and browser type</li>
      <li>the website from which the redirection was made</li>
      <li>the Internet Service Provider</li>
      <li>visited pages and</li>
      <li>requesting domain</li>
    </ul>
  </span>
  <p>In addition, the IP addresses of the requesting computers are logged. However, the person in charge does not draw any conclusions about an individual.<br>This data is only required to display the contents of our website correctly, to optimize the contents for you on a permanent basis and to support criminal prosecution in the event of hacker attacks. The data will be processed on the basis of our legitimate interest in accordance with Art. 6 para. 1 UAbs. 1 lit. f) DSGVO . The processing of the data is necessary for the operation of the website. The data is stored for as long as it is needed to fulfill the purpose and then automatically deleted.</p>

  <h2>3 Rights of data subjects</h2>
  <p>According to Art. 15 DSGVO you have the right to information about the processing of your personal data. In addition, you are free to exercise your rights of correction, deletion or, if deletion is not possible, restriction of processing and data transferability in accordance with articles 16-18, 20 DSGVO. If you wish to claim this right, please contact our data protection officer.</p>
  <p>Furthermore, you have the right to complain to the responsible supervisory authority at any time. If you are of the opinion that your personal data is not being processed in compliance with the data protection laws, we would politely ask you to contact our data protection officer. Furthermore, according to Art. 13, paragraph 2, letter b) DSGVO, you have the right to object to the processing of your personal data at any time.</p>

  <h2>4 Contact Forms</h2>
  <p>All your personal data and other information that you provide us with via the contact forms set up on our website will only be collected and processed for the purpose of processing and answering your inquiries on the basis of Art. 6 para. 1 UAbs. 1 lit. a) and b) DSGVO. Your data will be forwarded to us via our provider by e-mail. In case of non-availability, it is unfortunately not possible for us to contact you and process your request. An automated decision making process will not be carried out. A transfer of the personal data provided by you to a third country or an international organization does not take place and is not planned in the future. Your personal data will not be transferred to external third parties. You have the right to withdraw your consent at any time with effect for the future. The legality of the data processing that has taken place up to the time of revocation remains unaffected. Your personal data, which you communicate to us via the contact forms, will be stored by us for the duration of processing your request and will be deleted or blocked immediately after processing.</p>

  <h2>5 E-mail contact</h2>
  <p>On our website, it is possible to contact us via the e-mail address provided. If you take advantage of this possibility, the personal data transmitted with the e-mail will be stored and only collected and processed for the purpose of processing and answering your inquiries on the basis of Art. 6 para. 1 UAbs. 1 lit. a) and b) DSGVO. In case of non-availability, we are unfortunately not able to process your request. An automated decision making process will not be carried out. A transfer of the personal data provided by you to a third country or an international organization does not take place and is not planned in the future. A transfer of your personal data to external third parties does not take place. You have the right to revoke your consent at any time with effect for the future. The legality of the data processing that has taken place up to the point of revocation remains unaffected. Your personal data, which you communicate to us, will be stored by us for the duration of the processing of your request and will be deleted or blocked immediately after processing.</p>

  <h2>6 Newsletter</h2>
  <p>Insofar as you order our online newsletter, your personal data will only be processed for the purpose of regularly sending our newsletter and the associated measurement of success on the basis of Art. 6 Para. 1 UAbs. 1 lit. a) DSGVO. After registration, we will send you a confirmation e-mail which will allow us to verify your identity and re-confirm your approval. Your data will be forwarded to us via our provider by e-mail. In case of non-availability, we are unfortunately unable to provide you with our newsletter, contact you and process your request. An automated decision-making process will not be carried out. A transfer of the personal data provided by you to a third country or an international organization does not take place and is not planned in the future. Your personal data will not be forwarded to external third parties. You have the right to revoke your consent at any time with effect for the future. The legality of the data processing until revocation remains unaffected. Your personal data, which you provide us with when ordering the newsletter, will be stored by us until you revoke your consent to receive the newsletter and will be removed from the distribution list after you unsubscribe.</p>
  <p>We use the service MailChimp to send our newsletter. MailChimp is a service of The Rocket Science Group, LLC (512 Means Street, Suite 404, Atlanta, GA 30318 in the following called “Mailchimp”). The data stored during the registration is transmitted to Mailchimp and stored by Mailchimp. After registration, Mailchimp will e-mail you to confirm your registration. In addition, MailChimp offers various analysis possibilities on how the sent newsletters are viewed and used, e.g. to how many users an e-mail was sent, whether e-mails were rejected and whether users have unsubscribed from the list after receiving an e-mail. These evaluations are only group-related and are not used by us for individual reviews. MailChimp also uses the analysis tool Google Analytics by Google, Inc. and may include it in newsletters. Further details about Google Analytics can be found in this privacy policy under “Google Analytics”. Further information about privacy in connection with MailChimp can be found at: <a href="http://mailchimp.com/legal/privacy/">http://mailchimp.com/legal/privacy/</a><br>Mailchimp is certified under the EU/-US Privacy Shield, which regulates the protection of personal data transferred from the European Union to the USA. Further information can be found at: <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>. It is possible to unsubscribe from the newsletter at any time, for example via a link at the end of each newsletter.</p>

  <h2>7 Social media</h2>
  <p>We would like to get in contact with you and other parties, customers and users. For this we use different social networks. Which individual social networks we use can be found in these legal notes. However, we would like to point out that we have no influence on which data is collected. As a rule, however, this is data that is used for market research and advertising purposes. This is usually done by creating user profiles and the interests identified from them. This makes it possible to display customized advertising. For this reason, the use of social networks may also set cookies for you.</p>
  <p>Furthermore, we would like to point out that your data may also be processed outside the European Union and that you therefore run the risk of not being able to enforce your rights properly. With regard to providers based in the United States, however, we would like to point out that an EU-US Privacy Shield exists, which guarantees an equivalent level of data protection as in the European Union. Basically, we would like to provide you with information in the easiest and fastest way possible or give you the opportunity to share such information. Therefore, unless you have consented to data processing on the respective platform, the legal basis is our legitimate interest according to Art. 6 para. 1 UAbs. 1 lit. f) DSGVO. If you have consented on the respective platform, your consent pursuant to Art. 6 para. 1 subpara. 1 lit. a) DSGVO is the legal basis for the processing. You have the right to revoke your consent at any time with effect for the future. The legality of the data processing until revocation remains unaffected.</p>

  <h2>8 Online application</h2>
  <p>We process the personal data transmitted by you exclusively in the application procedure. Your data will be transmitted to us in encrypted form by the so-called Transport Layer Security (TLS). We will provide you with the necessary information in accordance with Art. 13 DSGVO upon receipt of your application by means of a reply e-mail or for downloading in the online application form. In the area of job advertisements, we use the software “Personio” of the company Personio GmbH, Buttermelcherstraße 16, 80469 Munich, Germany. If you use the online application form you will be redirected to the recruiting page of Alexander Thamm GmbH at Personio. For your application via the online form, the data protection statement stated there applies in its most current form.</p>

  <h3>8.1 Online registration for seminars and workshops</h3>
  <p>All your personal data and other information that you provide to us via the registration form regarding our seminars and workshops will only be collected and processed for the purpose of registering for the very same on the basis of Art. 6 para. 1 subpara. 1 letter b) DSGVO. Your data will be forwarded to us via our provider by e-mail. In case of non-availability, we are unfortunately unable to register you for one of our seminars and workshops. An automated decision-making process will not be carried out.</p>
  <p>A transfer of the personal data provided by you to a third country or an international organization does not take place and is not planned in the future. A transfer of your personal data to external third parties does not take place. Your personal data, which you provide us with via the registration form, will be stored by us for the duration of the contractual relationship and for the duration of the legal retention period and will be deleted or blocked afterwards.</p>

  <h3>8.2 Protection of stored data</h3>
  <p>We use technical and organizational security measurements to protect the personal data you provide to us from manipulation, loss, destruction or access by unauthorized persons. Our security measures are continuously improved and adapted according to the state of the art. It cannot be ruled out that data transmitted by you without encryption may be viewed by third parties during transmission. Please note that no conclusively secure transmission can be guaranteed with regard to data transmission via the Internet (e.g. communication by e-mail). Sensitive data should therefore either not be transmitted over the Internet at all or only via a secure connection (SSL).</p>

  <h3>8.3 Protection of minors</h3>
  <p>Consent to process personal data can only be given by a person of full age. For information society services, the consent of a child is permissible from the age of sixteen years in accordance with Art. 8 DSGVO.</p>

  <h2>9 Storage of anonymized data/cookies</h2>
  <p>Tracking cookies are used on this website. Cookies are text files that are stored on your computer and that allow an in-depth analysis of your website usage. However, these files collect and store the data exclusively in pseudonymous form. They are not used to identify you Personally and are not merged with data about the bearer of the pseudonym. We use this information to determine the attractiveness of our website and to continuously improve its content. The legal basis for the use of tracking cookies is the consent given by you in accordance with Art. 6 Para. 1 UAbs. 1 lit. a) DSGVO. You have the right to revoke your consent at any time with effect for the future. The legality of the data processing until revocation remains unaffected. Your data will not be transmitted to external parties. You can delete the cookies on your PC at any time.</p>

  <h2>10 Technically necessary cookies (session cookies)</h2>
  <p>In addition to the use of cookies for analysis purposes, other cookies are used to make our website more user-friendly. In order to use the full range of functions of our website, it is therefore necessary to allow session cookies for technical reasons. The purpose of using such cookies is to enable you using the website in a simpler and more user-friendly manner. It is therefore necessary that the browser can identify you even after you changed a page. The data stored and transmitted in this case are language preferences. The data is not used to create a user profile of you. The legal basis for the use of technical cookies is our legitimate interest according to Art. 6 para. 1 UAbs. 1 lit. f) DSGVO. It is not possible to use the website without session cookies.</p>

  <h2>11 Google</h2>
  <h3>11.1 Google Analytics</h3>
  <p>This website uses Google Analytics, a web analysis service of Google Ireland Ltd. (Gordon House, Barrow Street, Dublin 4, Ireland; hereinafter “Google”), which uses cookies.</p>
  <p>Cookies are text files which are stored on your computer and enable an analysis of your use of the website. Cookies usually transfer the generated information about your use of this website to a Google server in the USA and are stored there. However, since this website uses IP anonymization, your IP address will be truncated by Google before it is used within member states of the European Union or in other states party to the Agreement on the European Economic Area.</p>
  <p>Only in exceptional cases the full IP address will be transferred to a Google server in the USA and shortened there. On our behalf, Google uses this information to evaluate your use of the website, to compile reports on the website activities and to provide further services to the website operator in connection with the use of the website and the Internet. The IP address transmitted by your browser within the scope of Google Analytics is not merged with other Google data.</p>
  <p>You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent the collection of data generated by the cookie and related to your use of the website (including syour IP address) to Google and the processing of this data by Google by downloading and installing the browser plug-in available under the following link: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
  <p>The collection within the scope of this service will only take place after your explicit consent has been given. You can prevent the collection of your data by Google Analytics at any time, even after you have given your consent, by clicking on the “Deactivate Google Analytics” link in the section below. An opt-out cookie will be set to prevent the future collection of your data when you visit this website: Deactivate Google Analytics. If you delete the cookies in this browser, you will have to re-enable the opt-out cookie. The purpose of the survey is to evaluate the use of our website. Furthermore, we can use Google Analytics to generate reports on website activity and thus improve our website. The legal basis for this is your consent in accordance with Art. 6 para. 1 UAbs. 1 lit. a) DSGVO. Besides us, Google is to be mentioned as the recipient of the data. The data will be deleted as soon as they are no longer required for our recording purposes.</p>

  <h3>11.2 Google Optimize</h3>
  <p>Google Optimize is a part of Google Analytics and enables us to test the attractiveness of the content and the functionality of our website. For this purpose, individual elements, contents or functions up to the entire website are only played out to a certain percentage of the visitors and the respective change in the usage behavior is statistically evaluated (so-called A/B testing).</p>
  <p><strong>You can opt-out of Google storage of a user profile and information about your visit to our website and the placement of Google Tracking cookies on other websites by clicking this opt-out link.</strong></p>

  <h3>11.3 Google Tag Manager</h3>
  <p>Our website uses the Google Tag Manager, a service of Google Ireland Ltd. (Gordon House, Barrow Street, Dublin 4, Ireland; hereinafter “Google”). This allows us to manage website tags through an interface. The Google Tag Manager tool itself is a cookie-free domain and does not collect any personal data. The Google Tag Manager triggers other tags, which in turn may collect data. Google Tag Manager does not access this data. If deactivation is made at the domain or cookie level, it will remain in effect for all tracking tags implemented with Google Tag Manager. For more information about Google’s collection and processing of your information and your rights as a data subject, please see Google’s privacy policy at: https://policies.google.com/privacy?hl=de</p>
  <p>It is possible that in the course of using this Google service, personal data may be transferred to Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) in the USA. In this case Google has submitted to the EU-US Privacy Shield, which regulates the protection of personal data transferred from the European Union to the USA. Further information can be found at: https://www.privacyshield.gov/EU-US-Framework</p>
  <p>You can opt-out of Google Tag Manager storage of a user profile and information about your visit to our website and the placement of Google Tag Manager Tracking cookies on other websites by clicking this opt-out link.</p>
  <p><mat-slide-toggle [ngModel]="cookies.get('googleAnalytics')" (ngModelChange)="cookies.set('googleAnalytics', $event)" (change)="onChangeSlider()">Off | Google Analytics</mat-slide-toggle></p>


  <h3>12 Hotjar</h3>
  <p>We use Hotjar to better understand the needs of our users and to optimize the services on this website. Hotjar’s technology helps us gain a better understanding of our users’ experiences (e.g. how much time users spend on which pages, which links they click on, what they like and what they don’t, etc.) and this helps us to tailor our offering to our users’ feedback. Hotjar uses cookies and other technologies to collect information about the behavior of our users and about their end devices (in particular, IP address of the device (only collected and stored in anonymous form), screen size, device type (Unique Device Identifiers), information about the browser used, location (country only), language preferred for viewing our website). Hotjar stores this information in a pseudonymous user profile. The information is not used by Hotjar or by us to identify individual users or merged with other data about individual users. For more information, please see Hotjar’s privacy policy here: <a href="https://www.hotjar.com/legal/policies/privacy/">https://www.hotjar.com/legal/policies/privacy/</a></p>
  <p><strong>You can opt-out of Hotjar’s storage of a user profile and information about your visit to our website and the placement of Hotjar Tracking cookies on other websites by clicking this opt-out link.</strong></p>
  <p><mat-slide-toggle [ngModel]="cookies.get('hotjar')" (ngModelChange)="cookies.set('hotjar', $event)" (change)="onChangeSlider()">Off | Hotjar</mat-slide-toggle></p>

  <h3>13 LinkedIn (Link)</h3>
  <p>On our website we use a reference (link) to the social network LinkedIn, which is operated exclusively by LinkedIn Corporation (2029 Stierlin Court, Mountain View, CA 94043, USA; hereinafter referred to as “LinkedIn”). The link is identified by the LinkedIn logo (no LinkedIn plug-in). When you click on the LinkedIn logo, your browser establishes a direct connection to the LinkedIn servers. If you are already logged into LinkedIn through your personal account, information about your visit to our website isautomatically forwarded to LinkedIn. It is then possible for LinkedIn to associate your visit to the site with your account. We would like to point out that we have no knowledge of the content of thetransmitted data or how LinkedIn uses it. The following link will take you to LinkedIn’s privacy policy <a href="http://de.linkedin.com/legal/privacy-policy.">http://de.linkedin.com/legal/privacy-policy.</a></p>
</mat-sidenav-container>



