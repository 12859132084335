export class ThirdPartyModel {
  accept: boolean = false;
  name: string = '';

  constructor(cookiePolicy?: any) {
    if (cookiePolicy) {
      this.name = cookiePolicy.name;
      this.accept = cookiePolicy.accept;
    }
  }
}

export enum ThirdParty {
  GOOGLE_ANALYTICS = 'googleAnalytics',
  GOOGLE_TAGMANAGER = 'googleTagManager',
  HOTJAR = 'hotjar',
  COOKIE_STORAGE_NAME = 'attechradar_cookies'
}
