import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'techFilter'
})
export class TechFilterPipe implements PipeTransform {

  transform(value: any, searchString?: any): any[] {
    if (value) {
      if (!searchString) {
        return value;
      }
      searchString = searchString.toLowerCase();
      const technologies: any[] = [];
      for (const tech of value) {
        if (tech.name?.toLowerCase().indexOf(searchString) !== -1) {
          technologies.push(tech);
        }
      }
      return technologies;
    } else {
      return [];
    }
  }

}
