import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TechRadarComponent} from './tech-radar/tech-radar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {TechnologyDetailsComponent} from './technology-details/technology-details.component';
import {TechnologyOverviewComponent} from './technology-overview/technology-overview.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {TechRadarLegendComponent} from './tech-radar-legend/tech-radar-legend.component';
import {FormsModule} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {WorkStreamOverviewComponent} from './work-stream-overview/work-stream-overview.component';
//import {EnvServiceProvider} from './env.service.provider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TechRadarInfoDialogComponent} from './tech-radar/tech-radar-info-dialog/tech-radar-info-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ShareModule} from 'ngx-sharebuttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ShareButtonModule} from 'ngx-sharebuttons/button';
import { TechradarSearchbarComponent } from './techradar-searchbar/techradar-searchbar.component';
import { TechFilterPipe } from './utils/techFilter.pipe';
import { CookieConsentComponent } from './privacy/cookie-consent/cookie-consent.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { PrivacyPolicyComponent } from './privacy/privacy-policy/privacy-policy.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {CdkStepperModule} from '@angular/cdk/stepper';

@NgModule({
  declarations: [
    AppComponent,
    TechRadarComponent,
    TechnologyDetailsComponent,
    TechnologyOverviewComponent,
    TechRadarLegendComponent,
    WorkStreamOverviewComponent,
    TechRadarInfoDialogComponent,
    TechradarSearchbarComponent,
    TechFilterPipe,
    CookieConsentComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatGridListModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    FormsModule,
    MatTooltipModule,
    MatDialogModule,
    FlexLayoutModule,
    ShareModule,
    ShareIconsModule,
    FontAwesomeModule,
    ShareButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    CdkStepperModule,
    MatStepperModule
  ],
  /*providers: [DatePipe, TechFilterPipe,
    EnvServiceProvider],*/
  providers: [DatePipe, TechFilterPipe,
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
