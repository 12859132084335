import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {SearchService} from '../search.service';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';


@Component({
  selector: 'app-techradar-searchbar',
  templateUrl: './techradar-searchbar.component.html',
  styleUrls: ['./techradar-searchbar.component.scss']
})
export class TechradarSearchbarComponent implements OnInit {

  search!: string;
  private searchQuery = new BehaviorSubject<string>('');

  @Output() closeSearchbar = new EventEmitter<string>();

  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
    this.searchQuery.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap((query: string) => {
        this.searchService.changeSearchString(query);
      })
    ).subscribe();
  }
  
  onSearchChange(searchQuery: string): void {
    this.searchQuery.next(searchQuery);
  }

  clearSearch(): void {
    this.searchQuery.next('');
    this.closeSearchbar.emit('close');
  }

  onKeydown(event: KeyboardEvent): void
  {
     if ( event.code === 'Escape' ) {
       this.clearSearch();
     }
  }
}
