import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RadarTechnology} from '../tech-radar/radar-technology';

@Component({
  selector: 'app-tech-radar-legend',
  templateUrl: './tech-radar-legend.component.html',
  styleUrls: ['./tech-radar-legend.component.scss']
})
export class TechRadarLegendComponent implements OnInit{

  collapsed: Set<number> = new Set();

  constructor() { }

  @Input() segmentedTechnologies!: RadarTechnology[][][];

  @Input() quadrantNames!: string[];

  @Input() rings!: any[];

  @Input() hoveredTechnology!: RadarTechnology;

  @Output() technologyClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() mouseOverLegendEntry: EventEmitter<any> = new EventEmitter<any>();

  @Output() mouseLeaveLegendEntry: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void { }

  onTechnologyClick(technology: RadarTechnology): void {
    this.technologyClick.emit(technology);
  }

  onMouseOverLegendEntry(technology: RadarTechnology): void {
    this.mouseOverLegendEntry.emit(technology);
  }

  onMouseLeaveLegendEntry(): void {
    this.mouseLeaveLegendEntry.emit();
  }

  toggle(q: number, r: number): void {
    const hash = q * 1000 + r;
    if(this.collapsed.has(hash)) {
      this.collapsed.delete(hash);
    } else {
      this.collapsed.add(hash);
    }
  }

  isCollapsed(q: number, r: number): boolean {
    const hash = q * 1000 + r;
    return this.collapsed.has(hash);
  }
}
