import {assertPlatform, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Technology} from './technology';
import {TechnologyWrapper} from './technology-wrapper';
import {TechnologyList} from './technology-list';
import {NewTechnologyComment} from './new-technology-comment';
import {Observable, forkJoin, of} from 'rxjs';
import {map} from 'rxjs/operators';
import { parse } from '@fortawesome/fontawesome-svg-core';
import jsonFilesPaths from './jsonFilesPaths'
import { EvaluationLog } from './evaluation-log';

@Injectable({
  providedIn: 'root'
})
export class TechnologyService {

  /*

  constructor(private httpService: HttpClient, private env: EnvService) {
    this.httpHeaders = new HttpHeaders(env.httpsHeadersContents);
  }
  */

  constructor (private httpService: HttpClient) {}

  getTechnologiesFromJson (jsonFileNames: string[], jsonPathToFolder: string): Observable<Technology[]> {
    const requests: Observable<Technology>[] = jsonFileNames.map(fileName => {
      const url = jsonPathToFolder + fileName + '.json';
      return this.httpService.get(url).pipe(
        map(response => {
          const technology = response as Technology;
          console.log(technology)
          return technology;
        })
      );
    });

    return forkJoin(requests);
  }

  getMachineLearningTechnologies(): Observable<Technology[]> {
    return this.getTechnologiesFromJson(jsonFilesPaths.machineLearningTechnologiesFiles, jsonFilesPaths.pathToFolderMachineLearning)
  }

  getDataEngineeringTechnologies(): Observable<Technology[]> {
    return this.getTechnologiesFromJson(jsonFilesPaths.dataEngineeringTechnologiesFiles, jsonFilesPaths.pathToFolderDataEngineering)
  }

  getDataVisualizationTechnologies():  Observable<Technology[]> {
    return this.getTechnologiesFromJson(jsonFilesPaths.dataVisualizationTechnologiesFiles, jsonFilesPaths.pathToFolderDataVisualization)
  }


  getTechnologies(): Observable<Technology[]> {

    return forkJoin([
      this.getDataEngineeringTechnologies(),
      this.getDataVisualizationTechnologies(),
      this.getMachineLearningTechnologies(),
    ]).pipe(
      map(([dataEngineeringTechnologies, dataVisualizationTechnologies, machineLearningTechnologies]) => [...dataEngineeringTechnologies,...dataVisualizationTechnologies,...machineLearningTechnologies])
    );

  }
/*
  getTechnologies(workstream: string): Observable<Technology[]> {
    let technologiesList = []
    if (workstream === 'machine-learning') {
      return this.getMachineLearningTechnologies()
    }
    if (workstream === 'data-engineering') {
      return this.getDataEngineeringTechnologies()
    } else {
      return this.getDataVisualizationTechnologies()
    }
  }
*/
  filterTechnologyById(technologies: Technology[], id: string): Technology {
    const filteredTech = technologies.filter(tech => tech._id === id);
    console.log(filteredTech)
    return filteredTech[0];
  }

  getTechnology(id: string): Observable<Technology> {
    return this.getTechnologies().pipe(
        map(technologies => this.filterTechnologyById(technologies, id))
    );
  }



  addCommentToTechnology(newTechnologyComment: NewTechnologyComment): any {
    /*
    return this.httpService.post(this.env.apiUrl + '/api/v1/technologies/addComment',
      newTechnologyComment, {headers: this.httpHeaders, responseType: 'text'});
    */

  }
}
